<template>

  <b-row class="match-height">
    <b-col md="12">
      <b-card
        title="Tracking"
        style="height: 39rem !important;"
      >
        <l-map
          ref="map"
          :zoom="zoom"
          :center="userLocation"
          :options="mapOptions"
          style="height: 445px"
        >
          <l-tile-layer
            v-for="tileProvider in tileProviders"
            :key="tileProvider.name"
            :name="tileProvider.name"
            :visible="tileProvider.visible"
            :url="tileProvider.url"
            :attribution="tileProvider.attribution"
            layer-type="base"
          />
          <l-circle
            v-for="(marker,index) in trackings"
            :key="index"
            :lat-lng.sync="marker.position"

            :radius="circle.radius"
            :color="circle.color"
          />
          <l-control-attribution
            :position="attributionPosition"
            :prefix="attributionPrefix"
          />
          <l-geosearch :options="geoSearchOptions" />

          <l-marker
            v-for="marker in trackings"
            :key="marker.id"
            :lat-lng.sync="marker.position"
            :visible="marker.visible"
            :icon="iconT"
          >
            <l-tooltip :content="marker.tooltip" />
            <l-popup>
              <dl>
                <dt>Transporteur : <span
                  class="text-success"
                  v-text="marker.transporteur.lastname + ' ' + marker.transporteur.firstname"
                /> </dt>

                <dd>Immatriculation : 1025330</dd>
                <dt>Vitesse actuelle :120 km/h</dt>
                <dd>Niveau gazoil : 10L</dd>
              </dl>
            </l-popup>
          </l-marker>
        </l-map>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable global-require */
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'
import {
  LMap, LTileLayer, LMarker, LPopup, LControlAttribution, LCircle, LTooltip,
} from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import {
  required,
} from '@validations'
// eslint-disable-next-line import/no-cycle

import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-cycle
import useTrackings from '@/services/tracking/trackingService'

// eslint-disable-next-line import/no-cycle
import store from '@/store'

import { Icon, icon } from 'leaflet'
import { onMounted, reactive, ref } from '@vue/composition-api'
import { OpenStreetMapProvider } from 'leaflet-geosearch'
import LGeosearch from 'vue2-leaflet-geosearch'
// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
/* eslint-disable global-require */

export default {
  components: {
    LMap,
    LTileLayer,
    BCard,
    LControlAttribution,
    LPopup,
    BRow,
    LCircle,
    LGeosearch,
    BCol,
    LMarker,
    LTooltip,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
    }
  },

  setup() {
    const {
      getTracking, trackings,
    } = useTrackings()
    const zoom = ref(16)
    const attributionPrefix = ref('eBdfret')
    const attributionPosition = ref('bottomright')
    const userLocation = reactive({
      lat: null,
      lng: null,
    })
    const form = reactive({
      user_id: null,
    })
    const mapOptions = reactive({
      zoomControl: true,
      attributionControl: false,
      zoomSnap: true,
    })
    const geoSearchOptions = reactive({
      provider: new OpenStreetMapProvider(),
      showMarker: false,
      autoClose: true,
    })
    const circle = reactive({
      radius: 450,
      color: '#EA5455',
    })
    const tileProviders = ref([
      {
        name: 'OpenStreetMap',
        visible: true,
        attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      },
    ])
    const iconT = icon({
      iconUrl: require('@/assets/images/misc/truck-icon.png'),
      iconSize: [50, 50],
      iconAnchor: [16, 37],
    })

    onMounted(async () => {
      const data = JSON.parse(store.state.authStore.user)
      await getTracking(data.id).then(() => {
        userLocation.lat = trackings.value[0].lat
        userLocation.lng = trackings.value[0].lng
      })
    })
    // setInterval(getUserPosition, 500)
    return {
      zoom,
      iconT,
      geoSearchOptions,
      attributionPrefix,
      trackings,
      attributionPosition,
      mapOptions,
      userLocation,
      form,
      circle,
      tileProviders,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/map-leaflet.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}
</style>
